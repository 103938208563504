import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Features } from '@app/enums';
import { BoldPipe } from '@app/pipes/bold.pipe';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { SubscriptionCurrencyPipe } from '@app/pipes/subscription-currency.pipe';
import { SbFormModules } from '@app/shared/form-input/sb-form.modules';
import { FeatureService } from '@app/startup/feature.service';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import { BadgeComponent, ButtonComponent } from '@sb/ui';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { DecodeHtmlStringPipe } from '../../../../../pipes/decode-html-string.pipe';
import { SharedPipeModule } from '../../../../../pipes/shared-pipe.module';
import { TranslationParamsPipe } from '../../../../../pipes/translation-params.pipe';
import { AppState } from '../../../../../reducers';
import { AccountState } from '../../../../../reducers/account/account.model';
import { getAccountState } from '../../../../../reducers/account/account.service';
import { ContentStateComponent } from '../../../../../shared/content-state/content-state.component';
import { trackByIdFn } from '../../../../../shared/trackby';
import {
  InvoicePeriodType,
  PlanType,
  PlanTypeWeight,
  QuotaType,
  SubscriptionModel,
} from '../../../../+reports/shared/subscriptions/subscription.model';
import { SubscriptionService } from '../../../../+reports/shared/subscriptions/subscription.service';
import { allFeaturesUrls, planFeatures } from './choose-plan-card.model';
import { PlanCardComponent } from './plan-card/plan-card.component';

@Component({
  selector: 'choose-plan-card',
  templateUrl: './choose-plan-card.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    SharedPipeModule,
    TranslationParamsPipe,
    TooltipModule,
    DecodeHtmlStringPipe,
    ContentStateComponent,
    BoldPipe,
    AsyncPipe,
    FeatureFlagPipe,
    PlanCardComponent,
    SubscriptionCurrencyPipe,
    SbFormModules,
    BadgeComponent,
    RouterModule,
    ButtonComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChoosePlanCardComponent implements OnInit, OnDestroy {
  @Input()
  public yearlyBillingToggle: FormControl<boolean>;

  @Input()
  public trialExpired = false;

  @Input()
  public forceUpgrade = false;

  public subscriptions: SubscriptionModel[] = [];

  public trackBy = trackByIdFn;
  public hasPaymentMethod: boolean;
  public currentSubscription: SubscriptionModel;
  public selectedSubscription: SubscriptionModel;
  public isLoading: boolean;

  public isFetchingSubscriptions = true;

  public quotaType = QuotaType;
  public planType = PlanType;
  public planTypeWeight = PlanTypeWeight;
  public planFeatures = planFeatures;
  public countryCode: string;
  public hasEarlyAdopterPlan: boolean;
  public hasIntegrationPlus: boolean;
  public hasTrial: boolean;
  public Features: Features;

  private dataSubs = new Subscription();
  public inTrialPeriod: boolean;

  public integrationPlus: FormControl = new FormControl(false);

  public invoicePeriod: InvoicePeriodType;
  public invoicePeriodType = InvoicePeriodType;

  public switchToFreeLabel: string;
  public switchToBasicLabel: string;
  public switchToPremiumLabel: string;
  public displayPrice: string;

  public featuresUrl: string;

  public trialGranted: boolean;

  public constructor(
    private subscriptionService: SubscriptionService,
    private store: Store<AppState>,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private featureService: FeatureService,
  ) {
    this.featuresUrl = this.getFeaturesUrl();
  }

  public ngOnInit(): void {
    this.setAccountSubscriptionData();

    this.dataSubs.add(
      this.subscriptionService.getSubscriptions().subscribe((subscriptions) => {
        const validatedSubscriptions = this.validateSubscriptionQuota(subscriptions);
        this.isFetchingSubscriptions = false;
        this.checkCustomSubscription();

        this.subscriptions = this.orderSubscriptions(validatedSubscriptions);

        if (this.forceUpgrade) {
          // only allow higher plans and select heightest plan available
          this.subscriptions = this.filterSubscriptionDowngrades(this.subscriptions);
          this.selectHighestSubscriptionPlan();
        }

        this.cd.detectChanges();
      }),
    );

    this.dataSubs.add(
      this.subscriptionService.getSelectedSubscription().subscribe({
        next: (subscription) => {
          if (!subscription?.subscription) {
            return;
          }

          this.selectedSubscription = subscription.subscription;

          this.checkCustomSubscription();
          this.displayPrice = subscription.subscription.display_price;

          if (this.selectedSubscription.plan.type === this.planType.FREE) {
            this.integrationPlus.patchValue(false, { emitEvent: false });
            this.hasIntegrationPlus = false;
            this.integrationPlus.disable({ emitEvent: false });
          }

          if (this.selectedSubscription.plan.type === PlanType.FREE) {
            this.yearlyBillingToggle.disable();
            this.yearlyBillingToggle.setValue(false);
          } else {
            this.yearlyBillingToggle.enable();
          }

          this.cd.detectChanges();
        },
      }),
    );

    this.dataSubs.add(
      this.yearlyBillingToggle.valueChanges.pipe(distinctUntilChanged()).subscribe((yearlyBillingValue) => {
        this.invoicePeriod = yearlyBillingValue ? InvoicePeriodType.YEAR : InvoicePeriodType.MONTH;
        this.displayPrice = yearlyBillingValue
          ? this.selectedSubscription.display_year_price
          : this.selectedSubscription.display_price;

        this.subscriptionService.setSelectedSubscription({
          subscription: this.selectedSubscription,
          integrationPlus: this.hasIntegrationPlus,
          hasCustomSubscription: this.hasEarlyAdopterPlan,
          invoicePeriod: this.invoicePeriod,
        });
      }),
    );

    this.dataSubs.add(
      this.integrationPlus.valueChanges.subscribe((integrationPlusValue) => {
        this.hasIntegrationPlus = integrationPlusValue;
        this.subscriptionService.setSelectedSubscription({
          subscription: this.selectedSubscription,
          integrationPlus: this.hasIntegrationPlus,
          hasCustomSubscription: this.hasEarlyAdopterPlan,
          invoicePeriod: this.invoicePeriod,
        });
      }),
    );
  }

  public determineDisabledStateForPlanCards(subscription: SubscriptionModel) {
    if (!(this.inTrialPeriod || this.trialExpired)) {
      return false;
    }

    const differentSubscription = this.currentSubscription?.id !== subscription.id;
    const quotaReached = subscription.quotaReached?.length > 0;

    return (
      (differentSubscription && quotaReached) ||
      (differentSubscription &&
        this.currentSubscription.invoice_period === this.invoicePeriodType.YEAR &&
        this.currentSubscription.plan.weight > subscription.plan.weight &&
        !this.hasTrial)
    );
  }

  public setAccountSubscriptionData() {
    this.dataSubs.add(
      this.store
        .pipe(
          select(getAccountState),
          filter((accountState) => !!accountState),
        )
        .subscribe((accountState: AccountState) => {
          this.currentSubscription = accountState.subscription;
          this.hasIntegrationPlus = accountState.account.integration_plus;
          this.invoicePeriod = accountState.subscription.invoice_period;
          this.hasTrial = accountState.account.hasTrial;

          this.trialGranted = accountState.subscription.trial_granted;

          if (!this.subscriptionService.getHasSetValue()) {
            this.subscriptionService.setSelectedSubscription({
              subscription: this.currentSubscription,
              integrationPlus: this.hasIntegrationPlus,
              invoicePeriod: this.invoicePeriod,
            });
          }

          this.hasPaymentMethod = accountState.account.continue_subscription;
          this.inTrialPeriod = accountState.account.hasTrial;
          this.countryCode = accountState.account.country;

          this.integrationPlus.setValue(this.subscriptionService.getIntegrationPlus());
          this.yearlyBillingToggle.setValue(this.subscriptionService.getInvoicePeriod() === InvoicePeriodType.YEAR);

          this.checkCustomSubscription();
          this.cd.detectChanges();
        }),
    );
  }

  public getDisplayPrice(subscription: SubscriptionModel): string {
    // Check if the current subscription matches the selected subscription
    const isSameSubscription = this.currentSubscription?.id === subscription.id;

    // Check if the current subscription is billed yearly using the enum
    const isCurrentYearlyBilling = this.currentSubscription?.invoice_period === InvoicePeriodType.YEAR;

    // Check if the user prefers yearly billing
    const prefersYearlyBilling = this.yearlyBillingToggle.value;

    // If the subscription matches and both billing periods (current and preferred) match
    if (isSameSubscription && isCurrentYearlyBilling === prefersYearlyBilling) {
      // Use the current subscription's display price
      return this.currentSubscription.display_price;
    }

    // If the user prefers yearly billing, use the yearly price, otherwise use the standard price
    return prefersYearlyBilling ? subscription.display_year_price : subscription.display_price;
  }

  public selectSubscription(event: Event, subscription: SubscriptionModel) {
    if (subscription.plan.type !== PlanType.FREE && !this.hasIntegrationPlus && !this.yearlyBillingToggle.value) {
      this.integrationPlus.enable({ emitEvent: false });
    }
    this.subscriptionService.setSelectedSubscription({
      subscription: subscription,
      // If subscription is free, integrationPlus toggle is disabled
      // and unchecked but the value is updated at the same time this method is called
      // so the value is not updated yet at this point
      integrationPlus: subscription.plan.type === PlanType.FREE ? false : this.hasIntegrationPlus,
      hasCustomSubscription: this.hasEarlyAdopterPlan,
      invoicePeriod:
        this.yearlyBillingToggle.value && subscription.plan.type !== PlanType.FREE
          ? InvoicePeriodType.YEAR
          : InvoicePeriodType.MONTH,
    });
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  private orderSubscriptions(subscriptions: SubscriptionModel[]): SubscriptionModel[] {
    return subscriptions.sort((a, b) => this.planTypeWeight[a.plan.type] - this.planTypeWeight[b.plan.type]);
  }
  private getFeaturesUrl() {
    let url = allFeaturesUrls[this.translate.currentLang];

    if (url) {
      return url;
    }

    url = allFeaturesUrls[this.translate.currentLang.slice(0, 2)];

    if (url) {
      return url;
    }

    return 'https://www.shiftbase.com/pricing#price-table';
  }

  private validateSubscriptionQuota(subscriptions: SubscriptionModel[]): SubscriptionModel[] {
    if (!subscriptions) {
      return [];
    }

    return subscriptions.map((subscription) => {
      const quotas = Object.values(subscription.plan.quotas);
      const quotaReached = quotas.filter((quota) => quota.usage > quota.limit);

      return {
        ...subscription,
        quotaReached,
      };
    });
  }

  private checkCustomSubscription() {
    if (!this.currentSubscription || !this.subscriptions) {
      return;
    }

    this.hasEarlyAdopterPlan = this.currentSubscription.plan.weight === this.planTypeWeight[PlanType.EARLY_ADOPTER];
  }

  private selectHighestSubscriptionPlan() {
    const highestPlan = this.subscriptions.reduce((prev, current) =>
      prev.plan.weight > current.plan.weight ? prev : current,
    );
    this.selectSubscription(null, highestPlan);
  }

  private filterSubscriptionDowngrades(subscriptions: SubscriptionModel[]): SubscriptionModel[] {
    return subscriptions.filter((subscription) => subscription.plan.weight > this.currentSubscription.plan.weight);
  }

  public trackBySubscription(_: number, subscription: SubscriptionModel): string {
    return subscription.id;
  }
}
