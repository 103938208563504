import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChoosePlanCardComponent } from '@app/+authenticated/+account/billing/choose-plan/choose-plan-card/choose-plan-card.component';
import { BillingDetailsFormComponent } from '@app/+authenticated/+account/billing/forms/billing-details/billing-details-form.component';
import { OrderSummaryComponent } from '@app/+authenticated/+account/billing/order-summary/order-summary.component';
import { OrderCompletionComponent } from '@app/+authenticated/+account/billing/plan-wizard/order-completion/order-completion.component';
import {
  InvoicePeriodType,
  PlanType,
  SelectedSubscriptionModel,
  SubscriptionModel,
} from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { SubscriptionService } from '@app/+authenticated/+reports/shared/subscriptions/subscription.service';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { AppState } from '@app/reducers';
import { AccountModel } from '@app/reducers/account/account.model';
import { getAccount } from '@app/reducers/account/account.service';
import { getAuthenticatedStatus } from '@app/reducers/auth/auth.service';
import { TrackingService } from '@app/services/tracking.service';
import { ChargebeePortalSections } from '@app/shared/billing/billing.model';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@sb/ui';
import { BillingService } from '@shared/billing/billing.service';
import { SharedLaddaModule } from '@shared/shared-ladda.module';
import { TrackingEvent } from '@shiftbase-com/models';
import { MenuItem } from 'primeng/api';
import { StepsModule } from 'primeng/steps';
import { catchError, of, Subscription, switchMap } from 'rxjs';

import { BillingAndSummaryComponent } from './billing-and-summary/billing-and-summary.component';
import { ConfirmDowngradeComponent } from './confirm-downgrade/confirm-downgrade.component';
import { PlanWizardService } from './plan-wizard.service';

enum PlanWizard {
  CHOOSE_PLAN,
  BILLING_AND_SUMMARY,
  ORDER_COMPLETE,
}

@Component({
  selector: 'plan-wizard',
  templateUrl: './plan-wizard.component.html',
  standalone: true,
  imports: [
    StepsModule,
    ChoosePlanCardComponent,
    CommonModule,
    TranslateModule,
    BillingDetailsFormComponent,
    OrderSummaryComponent,
    SharedLaddaModule,
    OrderCompletionComponent,
    FeatureFlagPipe,
    BillingAndSummaryComponent,
    ButtonComponent,
    ConfirmDowngradeComponent,
  ],
})
export class PlanWizardComponent implements OnInit, OnDestroy {
  public planWizardStep: any;

  public skipBilling: boolean;
  public selectedSubscription: SelectedSubscriptionModel;
  public account: AccountModel;
  public showConfirmDowngrade = false;

  @Input()
  public forceUpgrade = false;

  @Output()
  public wizardCompleted = new EventEmitter();
  @Output()
  public checkoutLoading = new EventEmitter();
  @Output()
  public exitWizard = new EventEmitter();
  @Output()
  public restartWizard = new EventEmitter();

  @ViewChild(BillingAndSummaryComponent)
  public billingAndSummary: BillingAndSummaryComponent;

  public trialExpired = false;

  public isLoading = false;

  public PlanType = PlanType;

  public steps: MenuItem[] = [
    { label: this.translate.instant('Choose plan') },
    { label: this.translate.instant('Billing and summary') },
  ];
  public currentStepIndex = 0;

  private dataSubs = new Subscription();

  public yearlyBillingToggle = new FormControl(false);

  public isSaving: boolean;

  public nextBtnLabel: string;

  public buttonDisabled: boolean;

  public constructor(
    private translate: TranslateService,
    public subscriptionService: SubscriptionService,
    private billingService: BillingService,
    private cd: ChangeDetectorRef,
    private planWizard: PlanWizardService,
    private store: Store<AppState>,
    private readonly route: ActivatedRoute,
    private trackingService: TrackingService,
  ) {}

  public ngOnInit() {
    this.trackEvent('Plan Wizard Opened');

    this.dataSubs.add(
      this.store.select(getAccount).subscribe((account) => {
        this.account = account;
      }),
    );

    this.dataSubs.add(
      this.store.select(getAuthenticatedStatus).subscribe((status) => {
        this.trialExpired = status === 'expired';
      }),
    );

    this.updatePlanWizardSteps();
  }

  // Sets steps for the wizard, skipBilling and nextButtonDisabled
  private updatePlanWizardSteps() {
    this.dataSubs.add(
      this.subscriptionService
        .getSelectedSubscription()
        .subscribe((selectedSubscription: SelectedSubscriptionModel) => {
          this.selectedSubscription = selectedSubscription;

          this.skipBilling =
            this.account.continue_subscription || selectedSubscription?.subscription?.plan?.type === PlanType.FREE;

          this.planWizardStep = PlanWizard;

          if (this.skipBilling) {
            enum SkipPlanWizard {
              CHOOSE_PLAN,
              ORDER_SUMMARY,
              ORDER_COMPLETE,
            }
            this.planWizardStep = SkipPlanWizard;
          }

          this.setNextButtonLabelAndVisibility();
        }),
    );
  }

  private setNextButtonLabelAndVisibility() {
    if (this.currentStepIndex === this.planWizardStep.CHOOSE_PLAN && !this.skipBilling) {
      this.nextBtnLabel = this.translate.instant('Continue to billing');
    } else if (this.currentStepIndex === this.planWizardStep.CHOOSE_PLAN && this.skipBilling) {
      this.nextBtnLabel = this.translate.instant('Continue to summary');
    } else {
      this.nextBtnLabel = this.translate.instant('Next');
    }
  }

  public get hasSelectedSubscription(): boolean {
    return !!this.selectedSubscription.subscription;
  }

  public nextStep() {
    this.stepChanged(this.currentStepIndex + 1);
  }

  public previousStep() {
    this.stepChanged(this.currentStepIndex - 1);
  }

  public stepChanged(event: number) {
    this.showConfirmDowngrade = false;

    if (this.currentStepIndex === PlanWizard.ORDER_COMPLETE) {
      this.restartWizard.emit();
    }
    if (event === this.steps.length) {
      this.openCheckout();
      return;
    }
    this.currentStepIndex = event;
    this.setNextButtonLabelAndVisibility();
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public exitWiz() {
    this.trackEvent('Plan Wizard Completed');
    this.exitWizard.emit();
  }

  private openCheckout() {
    this.checkoutLoading.emit();
    const cbInstance = this.billingService.getChargeBeeInstance();
    cbInstance.logout();
    const cbPortalInstance = this.billingService.initChargebeePortalSession();
    cbPortalInstance.open(
      {
        paymentSourceAdd: () => {
          cbInstance.closeAll();
          this.completeWidget();
        },
        paymentSourceUpdate: () => {
          cbInstance.closeAll();
          this.completeWidget();
        },
        close: () => {
          this.completeWidget();
        },
      },
      { sectionType: ChargebeePortalSections.PAYMENT_SOURCES },
    );
  }

  public submitBillingForm(downgradeConfirmed = false) {
    this.billingAndSummary?.billingDetailsForm?.billingDetailsForm.markAllAsTouched();

    if (this.billingAndSummary?.billingDetailsForm?.billingDetailsForm.invalid) {
      return;
    }

    if (!downgradeConfirmed && this.isDowngrade) {
      this.showConfirmDowngrade = true;

      return;
    }

    this.isLoading = true;

    this.dataSubs.add(
      this.billingAndSummary?.billingDetailsForm?.submit().subscribe({
        complete: () => {
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;

          this.trackEvent('Plan Wizard Failed');
        },
      }),
    );
  }

  private completeWidget() {
    this.currentStepIndex = this.currentStepIndex + 1;
    this.cd.detectChanges();
    this.wizardCompleted.emit();

    if (!this.planWizard.paymentValid$.value) {
      this.trackEvent('Plan Wizard Failed');
    }
  }

  public get isDowngrade(): boolean {
    if (this.account.hasTrial || this.trialExpired) {
      return false;
    }
    return this.isPlanDowngrade || this.isIntegrationPlusDowngrade || this.isChangingBillingCycleToMonthly;
  }

  private get isIntegrationPlusDowngrade(): boolean {
    if (!this.selectedSubscription) {
      return false;
    }
    return this.account.integration_plus && !this.selectedSubscription.integrationPlus;
  }

  private get isChangingBillingCycleToMonthly(): boolean {
    if (!this.selectedSubscription) {
      return false;
    }
    return (
      this.selectedSubscription?.invoicePeriod === InvoicePeriodType.MONTH &&
      this.currentSubscription.invoice_period !== InvoicePeriodType.MONTH
    );
  }

  private get isPlanDowngrade(): boolean {
    if (!this.selectedSubscription) {
      return false;
    }
    return this.currentSubscription.plan.weight > this.selectedSubscription.subscription.plan.weight;
  }

  public get currentSubscription(): SubscriptionModel {
    return this.subscriptionService.getCurrentSubscription();
  }

  public get nextBillingDate() {
    return this.subscriptionService.getCurrentSubscription()?.next_billing_date;
  }

  public cancelDowngrade() {
    this.showConfirmDowngrade = false;
  }

  public confirmDowngrade() {
    if (this.currentStepIndex === this.planWizardStep.ORDER_SUMMARY && this.skipBilling) {
      this.confirmPlanChange(true);
      return;
    }

    if (this.currentStepIndex === this.planWizardStep.BILLING_AND_SUMMARY) {
      this.submitBillingForm(true);
      return;
    }
  }

  public confirmPlanChange(downgradeConfirmed = false) {
    if (!downgradeConfirmed && this.isDowngrade) {
      this.showConfirmDowngrade = true;

      return;
    }

    this.isSaving = true;

    this.dataSubs.add(
      this.subscriptionService
        .getSelectedSubscription()
        .pipe(
          switchMap((selectedSubscription) =>
            this.subscriptionService
              .saveSubscription(
                selectedSubscription.subscription.id,
                selectedSubscription.integrationPlus,
                selectedSubscription.invoicePeriod,
              )
              .pipe(
                catchError(() => {
                  this.trackEvent('Plan Wizard Failed');
                  this.isSaving = false;
                  return of(null);
                }),
              ),
          ),
        )
        .subscribe(() => {
          this.planWizard.paymentValid$.next(true);
          this.isSaving = false;
          this.exitWiz();
        }),
    );
  }

  protected trackEvent(event: TrackingEvent) {
    this.trackingService.event(event, {
      originator: this.trackingOriginator,
    });
  }

  private get trackingOriginator(): string {
    return this.route.routeConfig.path === 'trial-expired'
      ? 'trial-basic-or-premium-ends'
      : this.route.snapshot.params['originator'];
  }
}
